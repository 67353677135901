<template>
    <div class="is-full-height is-flex is-flex-direction-column is-justify-content-end">
    <div>
        <h1 class="is-size-4">Scanned Items</h1>
        <h2 class="is-size-6">Choose Items to put into basket</h2>
    </div>
   
      <div class="is-full-height">
        <div class="list-item-container" v-for="item in $store.state.data.scannedItems" :key="item">
          <ListItem v-if="item" :item="item" @click="handleItemClicked($event,item)" :class="{'is-select-mode':selectModeOn, 'is-selected':selectedItems.find((i)=>i?.id == item?.id)}" :swipetoremove="true" @swipeRemove="handleSwipeRemove($event,item.id)" />
        </div>
      </div>


      <div :class="{'listButtonBar mb-1 is-flex is-flex-direction-column is-full-width':true,'is-select-mode':selectModeOn}">
        <div class="mb-1 is-flex is-flex-direction-row is-justify-content-space-between is-full-width">
          <div>
            <button class="ml-2 button is-rounded" v-if="!selectModeOn" @click="$router.push('/Scan')">SCAN MORE</button>
          </div>
          <div>
            <button v-if="$store.state.data.scannedItems.length > 0" :class="{'mr-2 button is-rounded has-background-purple-1 has-text-light':true,'has-background-purple-5 has-text-dark':selectModeOn}" @click="handleSelectClicked">{{selectModeOn ? "READY" : "SELECT"}}</button>        
          </div>
        </div>
        <div class="selectButtonContainer is-flex is-flex-direction-row is-justify-content-space-between is-full-width">
          <div>
            <button class="ml-2 button is-rounded" v-if="selectModeOn" @click="handleSelectAllClicked">☑️ SELECT ALL</button> 
          </div>
          <div>
            <button class="mr-2 button is-rounded" v-if="selectModeOn && selectedItems.length > 0" @click="handleAdd2Basket">ADD TO BASKET</button>
          </div>
        </div>        
      </div>
      <div style="height: 11vh"></div>

    </div>
</template>

<script>

import ListItem from '@/components/Template/ListItem'
import { ref } from '@vue/reactivity'
import { useStore} from 'vuex'
import { onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'

export default {
  components:{ListItem},
  setup() {
    const store = useStore();
    const router = useRouter();
    const selectModeOn = ref(false);
    const selectedItems = ref([]);

    onMounted(()=>{
      if (store.state.data.scannedItems.length < 1){
        router.push('/Scan')
      }
    })

    const handleSelectClicked = () =>{
     console.log("select clicked");
      // activate SELECTION MODE
      selectModeOn.value = !selectModeOn.value;
      // empty selection
      selectedItems.value = [];
    } 

    const handleItemClicked = (e,item)=>{
      
      if (!selectModeOn.value) router.push('/Detail?id='+item.id)
      
      toggleItemSelect(item)
      console.log(selectedItems.value);
    }

    const handleSelectAllClicked = ()=>{
      if (selectedItems.value.length < store.state.data.scannedItems.length){
        selectedItems.value = store.state.data.scannedItems
      }
      else{
        selectedItems.value = []
      }
    }

    const handleAdd2Basket = async () =>{
      await store.dispatch('add2Cart',{items:selectedItems.value});
      await store.dispatch('removeItemsFromListById',{key:'scannedItems',items:selectedItems.value})
      selectedItems.value = [];
      selectModeOn.value = false;
    }


    const handleSwipeRemove = (e,id) =>{
      store.dispatch("removeItemsFromListById",{key:"scannedItems",items:[{id}]})
    }

    function toggleItemSelect(item) {
      console.log("item selected or unselected")
      
      let index=selectedItems.value.findIndex((i) => i?.id===item?.id)
      if(index===-1) {
        selectedItems.value.push(item)
      }
      else {
        selectedItems.value.splice(index, 1)
      }
    }

   return {handleAdd2Basket,handleSelectAllClicked,handleSelectClicked,handleItemClicked,selectModeOn,selectedItems,handleSwipeRemove}
  },
}

 
</script>